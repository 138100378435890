export const time_table_consts = {
    main_class: '.timetable_paginate',
    back_control_class: '.back',
    next_control_class: '.next',
    is_hidden_class: 'is-hidden',
    disabled_class: 'disabled',
    is_selected_class: 'is-selected',
    interval_time_class: 'timetable_paginate--top-controls--text',
    navigation_controls_class: 'timetable_paginate--bottom-controls',
    download_class: 'timetable_download',
    show_plan_link: '#link-show-plan'
};
