
const line_regex = /^line:[a-zA-Z0-9:_-]+/;
const network_regex = /^network:[a-zA-Z0-9:_-]+/;
const stop_area_regex = /^stop_area:[a-zA-Z0-9:_-]+/;
const stop_point_regex = /^stop_point:[a-zA-Z0-9:_-]+/;
const desktopBreakPoint = 600;

export function hasValidLine(line) {
    return line_regex.test(line);
}

export function hasValidNetwork(network) {
    return network_regex.test(network);
}

export function hasValidStopArea(stopArea) {
    return stop_area_regex.test(stopArea);
}

export function hasValidStopPoint(stopPoint) {
    return stop_point_regex.test(stopPoint);
}

export function hasValidStopId(stopId) {
    return hasValidStopArea(stopId) || hasValidStopPoint(stopId);
}

// la position de la map en fonction du board
export function getMapPosition() {
    const boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;
    if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
        return {
            paddingTopLeft: L.point(boardWidth - 250, -250),
            paddingBottomRight: L.point(0, 0),
            maxZoom: 14
        }
    }else {
        return {
            paddingTopLeft: L.point(boardWidth + 30, 32),
            paddingBottomRight: L.point(0, 32)
        }
    }
};

export function setElementAttributes(element, attributes) {
    Object.keys(attributes).forEach(attr => {
        element.setAttribute(attr, attributes[attr]);
    });
}

export function createBackLink(previousEl, currentEl, departures_conf) {
    const backEl = document.createElement('span');
    backEl.classList.add('schedule-line-form__back');
    backEl.innerHTML = '<i role="presentation" class="ikisio ikisio-back" aria-hidden="true"></i>' + Translator.trans('schedule.form.back');

    // click on back
    backEl?.addEventListener('click', function () {

        if(departures_conf) { // only clean on departure, line back button will clear all
            document.dispatchEvent(new CustomEvent('remove_by_layer_key', {detail: {clean_data:true, target_layer_key:  departures_conf.layer_section_name}}));
        }

        // display schedule home
        previousEl.classList.remove('gl-hide');
        currentEl.remove();

        // hide map layers
        if (currentEl.dataset.layerSection) {
            // deleteLayers(currentEl.dataset.layerSection);
            let clean_data = false;
            if (currentEl.dataset.layerSection == 'schedule_line') {
                clean_data = true;
            }
            document.body.dispatchEvent(new CustomEvent("hide_layers_section_event", {detail: {section: "schedule", clean_data: clean_data}}))
        }

        if (previousEl.dataset.layerSection) {
            document.body.dispatchEvent(new CustomEvent("display_layers_on_map_event", {detail: {linesId: previousEl.dataset.layerSection, section: "schedule"}}));
            document.body.dispatchEvent(new CustomEvent("opacity_layers_section_event", {detail: {
                section: "schedule",
                layer: previousEl.dataset.layerSection,
                opacity: 1
            }}));
        }

        document.body.dispatchEvent(new CustomEvent('schedule_back_clicked', {
            detail: {layer_section: currentEl.dataset.layerSection}
        }));
    });

    return backEl;
}

export function createLoader() {
    return `<div class="loader"><span class="loader__content"></span></div>`;
}

export function transformDateTime(dateString) {
    return new Date(dateString.substr(0, 4),
        dateString.substr(4, 2) - 1,
        dateString.substr(6, 2),
        dateString.substr(9, 2),
        dateString.substr(11, 2),
        dateString.substr(13, 2)
    );
}

export function addQueryParametersToRoute(params = {}) {
    removeQueryParametersFromRoute(Object.keys(params));

    const queryString = new URLSearchParams(params).toString();
    const route = window.location.href.includes('?')
        ? `${window.location.href}&${queryString}` : `${window.location.href}?${queryString}`;
    replaceRoute(route);
}

export function getAllQueryParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = {};

    for (const [key, value] of urlParams) {
        paramsObject[key] = value;
    }

    return paramsObject;
}

export function getQueryParamByName(name) {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(name);
}

export function removeQueryParametersFromRoute(params) {
    const urlObj = new URL(window.location.href);

    params.forEach(param => {
        urlObj.searchParams.delete(param);
    });

    replaceRoute(urlObj.toString());
}

export function informationMessage(message) {
    const el = document.createElement('div');
    el.innerHTML = `<span>${message}</span>`;

    return el;
}

function replaceRoute(route) {
    const newState = (window.history.state || 0) + 1;

    window.history.replaceState(newState, 'Pushed: ' + newState, route);
}
