import { time_table_consts } from "../core/constants";

export function downloadTimeTableButton(departureObject) {
    const buttonDownload = document.createElement('a');
    const linkText = Translator.trans('timetables.download');

    buttonDownload.className = time_table_consts.download_class;
    buttonDownload.href = '#';
    buttonDownload.title = linkText;
    buttonDownload.dataset.lineId = departureObject.line_id;
    buttonDownload.dataset.stopPointId = departureObject.stop_point;
    buttonDownload.dataset.arrivalName = departureObject.arrival.stop_area_name;
    buttonDownload.innerText = linkText;

    return buttonDownload;
}

export function downloadEventListeners() {
    document.querySelector('.' + time_table_consts.download_class)?.addEventListener('click', function (e) {
        const selectedTimetable = document.querySelector('.timetable__by-direction:not(.gl-hide)');
        const directionName = selectedTimetable?.dataset.directionId;
        const directionLabel = selectedTimetable?.dataset.direction;
        const fromDatetime = document.querySelector('input[name=timetable_datepicker]')?.value;
        const query = {
            type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '',
            lineId: e.currentTarget.dataset.lineId,
            directionName: directionName,
            stopPointId: e.currentTarget.dataset.stopPointId,
            directionLabel: directionLabel,
            arrivalName: e.currentTarget.dataset.arrivalName
        };

        if (fromDatetime) {
            query.from_datetime = fromDatetime;
        }

        const url = Routing.generate('schedule_timetable_download', query);

        window.open(url);
    });
}
