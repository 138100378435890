import {pictoLines} from "./index";
import {displayLineOnMap, displayStopPointsOnMap} from "../map";
import {createBackLink, createLoader, getQueryParamByName, setElementAttributes} from "../../utils";
import {callApiPtObjects} from "../api";
import {displayScheduleLineDepartures} from "../line-departures";
import {arrival_stop_area_id, departure_arrival_event, stop_area_id} from '../..';
import { clearStopToolTip } from "../map";

export const line_form_layer = 'schedule_line';

const line_config = {
    search_form_id: 'ctp-searchSchedule',
    select_form: 'schedule-line-form',
    select_form_title: 'schedule-line-form__title',
    layer_section_name: line_form_layer,
    back_el_class: 'schedule-line-form__back'
}

export function displayLineFormById(id) {
    if (!id) {
        return;
    }
    const lineFormEl = document.querySelector(`.${line_config.select_form}`);
    if (lineFormEl) {
        return;
    }

    const searchForm = document.getElementById(line_config.search_form_id);
    searchForm.classList.add('gl-hide');

    const lineContainerEl = document.createElement('div');
    lineContainerEl.classList.add(line_config.select_form);
    lineContainerEl.dataset.layerSection = line_config.layer_section_name;
    lineContainerEl.innerHTML = createLoader();

    searchForm.after(lineContainerEl);

    // Get line information
    callApiLinesById(id)
        .then(function (data) {
            // clean loader
            lineContainerEl.innerHTML = '';

            // create back element
            lineContainerEl.appendChild(createBackLink(searchForm, lineContainerEl));

            // create line header
            const lineHeader = document.createElement('div');
            lineHeader.classList.add('schedule-line-form__header');
            lineHeader.innerHTML = pictoLines(data.lines);
            lineHeader.innerHTML += `<span class="${line_config.select_form_title}" tabindex="0">${Translator.trans('schedule.form.line.header_title')}</span>`;

            lineContainerEl.appendChild(lineHeader);

            // create line body
            const lineBody = document.createElement('div');
            lineBody.classList.add('schedule-line-form__body');
            lineBody.classList.add('select');
            lineBody.innerHTML = createLoader();
            lineContainerEl.appendChild(lineBody);

            if (!data.lines) {
                return;
            }

            // manage map for selected line
            displayLineOnMap(data.lines[0], line_config.layer_section_name);
            callApiStopPointsByLineId(id).then(function (result) {
                displayStopPointsOnMap(result.stop_points, id, data.lines[0], line_config.layer_section_name);
            });
            callApiStopAreasByLineId(id)
                .then(function (result) {
                    lineBody.innerHTML = '';

                    const currentStopAreaDeparture = getQueryParamByName(stop_area_id);
                    const currentStopAreaArrival = getQueryParamByName(arrival_stop_area_id);
                    const departureObject = {
                        ...data.lines[0],
                        line_id: id
                    };
                    lineBody.appendChild(createStopAreasChoiceType(result.stop_areas, 'departure', currentStopAreaDeparture, data.lines[0].color));
                    lineBody.appendChild(createStopAreasChoiceType(result.stop_areas, 'arrival', currentStopAreaArrival, data.lines[0].color));
                    lineBody.appendChild(createSubmitForm(departureObject));
                    if (currentStopAreaDeparture && currentStopAreaArrival) {
                        lineFormValidator(departureObject);
                    }
                });
        });
}

async function callApiLinesById(id) {
    return callApiPtObjects({
        public_transport_object_type: 'lines',
        public_transport_object_id: id,
        action: 'lines'
    });
}

async function callApiStopAreasByLineId(id) {
    return callApiPtObjects({
        public_transport_object_type: 'lines',
        public_transport_object_id: id,
        action: 'stop_areas',
        count: window.Kisio.schedule_config.line.max_stop_points
    });
}

async function callApiStopPointsByLineId(id) {
    return callApiPtObjects({
        public_transport_object_type: 'lines',
        public_transport_object_id: id,
        action: 'stop_points',
        count: window.Kisio.schedule_config.line.max_stop_points
    });
}

function createStopAreasChoiceType(stopAreas, type, currentStopArea = null, lineColor = null) {
    const choiceTypeSummary = document.createElement('summary');
    choiceTypeSummary.classList.add('radios');

    const choiceTypeUl = document.createElement('ul');
    choiceTypeUl.classList.name = 'list';

    // create placeholder
    createInputsList(
        choiceTypeSummary,
        Translator.trans(`schedule.form.line.${type}_placeholder`),
        `default_${type}`,
        `item_${type}`,
        currentStopArea ? false : true
    );

    // Count occurrences of each name
    const nameCount = {};
    stopAreas.forEach(stopArea => {
    nameCount[stopArea.name] = (nameCount[stopArea.name] || 0) + 1;
    });

    stopAreas.forEach(stopArea => {
        const label = nameCount[stopArea.name] > 1 ? stopArea.label : stopArea.name;
        createInputsList(
            choiceTypeSummary,
            label,
            stopArea.id + type,
            `item_${type}`,
            currentStopArea === stopArea.id,
            stopArea
        );
        createLabelsList(choiceTypeUl, label, stopArea.id + type);
    });

    const choiceType = document.createElement('details');
    choiceType.classList.add('schedule-line-form__choice-type');
    choiceType.classList.add(type);

    choiceType.appendChild(choiceTypeSummary);
    choiceType.appendChild(choiceTypeUl);

    // manage open/close choice list
    choiceType.querySelectorAll('input').forEach(function (input) {
        input.addEventListener('click', function () {
            clearStopToolTip(input.name);
            dispatchChangedDepartureArrivalEvent(type, input, lineColor);
            manageSubmitBtn();
            if (choiceType.hasAttribute("open")) {
                choiceType.removeAttribute("open");
                return;
            }

            choiceType.setAttribute("open", "");
        });
    });
    return choiceType;
}

function createInputsList(parent, value, id, name, checked = false, stopArea = null) {
    const input = document.createElement('input');
    const attributes = {
        type: 'radio',
        name: name,
        id: id,
        title: value,
        tabIndex: '-1'
    };

    if (stopArea) {
        input.dataset.stopAreaId = stopArea.id;
        input.dataset.lat = stopArea?.coord.lat;
        input.dataset.lon = stopArea?.coord.lon;
    }

    if (checked) {
        attributes.checked = "checked";
    }

    setElementAttributes(input, attributes);

    parent.appendChild(input);
}

function createLabelsList(parent, value, id) {
    const choiceTypeLi = document.createElement('li');
    const choiceTypeLabel = document.createElement('label');
    choiceTypeLabel.setAttribute('for', id);
    choiceTypeLabel.setAttribute('tabIndex', 1);
    choiceTypeLabel.innerHTML = value;

    choiceTypeLabel.addEventListener('keyup', function(e) {
        if (e.keyCode == 32 || e.keyCode == 13) {
            JKisio(this).trigger('click');
        }
    });

    choiceTypeLi.appendChild(choiceTypeLabel);

    choiceTypeLi.addEventListener('keyup', function (e) {
        if (['Enter', 'NumpadEnter'].includes(e.key)) {
            e.target.click();
        }
    });

    parent.appendChild(choiceTypeLi);
}

function createSubmitForm(departureObject) {
    const buttom = document.createElement('button');
    buttom.classList.add('ctp-button-primary', 'lg', 'disabled');
    buttom.type = 'submit';

    const span = document.createElement('span');
    span.textContent = Translator.trans('schedule.form.go');

    buttom.appendChild(span);

    // click on submit
    buttom.addEventListener('click', function () {
        lineFormValidator(departureObject);
    });

    return buttom;
}

function lineFormValidator(departureObject) {
    const selectedChoices = getSelectedChoices();
    if (selectedChoices) {
        displayScheduleLineDepartures({
            ...departureObject,
            ...selectedChoices
        });
    }
}

function manageSubmitBtn() {
    const selectedChoices = getSelectedChoices();
    const submitBtnEl = document.querySelector(`.${line_config.select_form} button`);

    if (selectedChoices) {
        submitBtnEl.classList.remove('disabled');

        return;
    }

    if (!submitBtnEl.classList.contains('disabled')) {
        submitBtnEl.classList.add('disabled');
    }
}

function getSelectedChoices() {
    const departureChoiceType = document.querySelector('input[name="item_departure"]:checked');
    const arrrivalChoiceType = document.querySelector('input[name="item_arrival"]:checked');

    if (departureChoiceType?.dataset.stopAreaId &&
        arrrivalChoiceType?.dataset.stopAreaId &&
        departureChoiceType?.dataset.stopAreaId !== arrrivalChoiceType?.dataset.stopAreaId
    ) {
        return {
            departure: {
                stop_area_name: departureChoiceType.title,
                stop_area: departureChoiceType.dataset.stopAreaId
            },
            arrival: {
                stop_area: arrrivalChoiceType.dataset.stopAreaId,
                stop_area_name: arrrivalChoiceType.title
            }
        }
    }

    return null;
}

function dispatchChangedDepartureArrivalEvent(type, input, lineColor = null) {
    const inputfields = {};
    inputfields[type] = {
        id: input.dataset.stopAreaId,
        name: input.name,
        title: input.title,
        lat: input.dataset.lat,
        lon: input.dataset.lon,
        color: lineColor
    }

    document.body.dispatchEvent(new CustomEvent(departure_arrival_event, {detail: inputfields}));
}
