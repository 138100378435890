import {callApiPtObjects} from "../api";
import {removeLinesSection} from "../line";

const modes_config = {
    content_class: '.schedule-lines__content',
    network_prefix: 'network:'
}

export function updateModesByStopAreaId(stopAreaId) {
    const physicalModes = callApiPhysicalModesByStopAreaId(stopAreaId);
    physicalModes.then(function (data) {
        if (!data.physical_modes) {
            return;
        }

        const modes = data.physical_modes.map(mode => mode.id);
        removeLinesSection();
        refreshModesList(modes);
    });
}

export function resetModesList() {
    const modesEl = document.querySelector(modes_config.content_class);
    [...modesEl?.children].forEach(function (child) {
        if (child.classList.contains('show')) {
            child.classList.remove('show');
        }

        if (child.classList.contains('gl-hide')) {
            child.classList.remove('gl-hide');
        }
    });
}

export function resetAllModes() {
    const modesEl = document.querySelector(modes_config.content_class);
      [...modesEl?.children].forEach(function (child) {
        const modeCollapse = document.querySelector(`.schedule-section[data-mode-name="${child.dataset.modeName}"]`);
        if (child.classList.contains('gl-hide') && modeCollapse.classList.contains('show')) {
            child.classList.remove('gl-hide');
        }
    });
}

export async function refreshModesListByNetworkId(networkId) {
    if (!networkId || !networkId.toLowerCase().includes(modes_config.network_prefix)) {
        // display all modes
        resetModesList();

        return;
    }

    const physicalModes = await callApiPhysicalModesByNetworkId(networkId);
    if (physicalModes.physical_modes) {
        const modes = physicalModes.physical_modes.map(mode => mode.id);
        removeLinesSection();
        refreshModesList(modes);
    }
}

function callApiPhysicalModesByStopAreaId(stopAreaId) {
    let params = {
        public_transport_object_type: 'stop_areas',
        public_transport_object_id: stopAreaId,
        action: 'physical_modes',
        path_filter: window.Kisio.network_id && window.Kisio.route_schedules_networks?.excluded?.length === 0 ? `stop_areas/${stopAreaId}/networks/${window.Kisio.network_id}` : null,
        disable_route_schedules_excluded: true
    };

    if(window.Kisio.route_schedules_networks_filter && window.Kisio.route_schedules_networks_filter.length > 0) {
        let prefix = 'network.id(';
        let suffix = ')';

        params['filter'] = window.Kisio.route_schedules_networks_filter.map(filter => `${prefix}${filter}${suffix}`).join(' or ');
    }
    return callApiPtObjects(params);
}

export function refreshModesList(modes) {
    const modesEl = document.querySelector(modes_config.content_class);
    [...modesEl?.children].forEach(function (child) {
        if (child.classList.contains('show')) {
            child.classList.remove('show');
        }
        child.classList.remove('gl-hide');
        if (!modes.includes(child.dataset.modeName)) {
            child.classList.add('gl-hide');
        }
    });
}

function callApiPhysicalModesByNetworkId(networkId) {
    let params = {
        public_transport_object_type: 'networks',
        public_transport_object_id: networkId,
        action: 'physical_modes',
        disable_route_schedules_excluded: true
    };

    return callApiPtObjects(params);
}
