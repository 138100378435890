import { time_table_consts } from '../core/constants';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';

export function addNextBackPageListeners (timetableElement) {
    if (!timetableElement) {
        return;
    }

    // listener to display next page
    timetableElement.querySelector(time_table_consts.next_control_class)?.addEventListener('click', function () {
        updateDisplayedTable(timetableElement, 1);
    });

    // litener to display prev page
    timetableElement.querySelector(time_table_consts.back_control_class)?.addEventListener('click', function () {
        updateDisplayedTable(timetableElement, -1);
    });

    timetableElement.querySelector(time_table_consts.next_control_class)?.addEventListener('keyup', function(e) {
        if (e.keyCode == 32 || e.keyCode == 13) {
            JKisio(this).trigger('click');
        }
    });

    timetableElement.querySelector(time_table_consts.back_control_class)?.addEventListener('keyup', function(e) {
        if (e.keyCode == 32 || e.keyCode == 13) {
            JKisio(this).trigger('click');
        }
    });
}

export function addNavigationControlsListeners (timetableElement) {
    if (!timetableElement) {
        return;
    }

    // listener to show selected page
    timetableElement.querySelector('.' + time_table_consts.navigation_controls_class)?.addEventListener('click', function (e) {
        let controlIndex = e.target.getAttribute('data-controls-index');

        if (controlIndex) {
            updateDisplayedTable(timetableElement, 0, parseInt(controlIndex));
        }
    });
}

/*
 * direction : -1 for back | 1 for next
 */
function updateDisplayedTable (timetableElement, direction, targetIndex = null) {
    const selectedTable = timetableElement.querySelector('table:not(.'+ time_table_consts.is_hidden_class +')');
    const targetTableIndex = targetIndex ?? parseInt(selectedTable?.dataset.index) + (1 * direction);
    const targetTable = timetableElement.querySelector('table[data-index="' + targetTableIndex + '"]');

    updateNextBackControls(timetableElement, targetTableIndex);

    selectedTable?.classList.add(time_table_consts.is_hidden_class);
    targetTable?.classList.remove(time_table_consts.is_hidden_class);

    updateNavigationControls(timetableElement, targetTableIndex);
    let timetablePaginate = selectedTable.closest('.timetable_paginate');


}

function updateNextBackControls (timetableElement, tableIndex) {
    const nextControl = timetableElement.querySelector(time_table_consts.next_control_class);
    const backControl = timetableElement.querySelector(time_table_consts.back_control_class);

    const nextMoreTables = timetableElement.querySelector('table[data-index="' + (tableIndex + 1) + '"]');
    const backMoreTables = timetableElement.querySelector('table[data-index="' + (tableIndex - 1) + '"]');

    if (!nextMoreTables) {
        nextControl?.classList.add(time_table_consts.disabled_class);
    } else {
        nextControl?.classList.remove(time_table_consts.disabled_class);
    }

    if (!backMoreTables) {
        backControl?.classList.add(time_table_consts.disabled_class);
    } else {
        backControl?.classList.remove(time_table_consts.disabled_class);
    }
}

function updateNavigationControls (timetableElement, index) {
    const selectedControl = timetableElement?.querySelector('.' + time_table_consts.is_selected_class);
    const targetControl = timetableElement?.querySelector('[data-controls-index="'+ index +'"]');

    selectedControl?.classList.remove(time_table_consts.is_selected_class);
    targetControl?.classList.add(time_table_consts.is_selected_class);
}
