import {displayLineFormById} from "./line-form";
import {getDisruptionsSevirityById} from "../disruptions";
import {generateLogoFromConfig} from "pnp_core_bundle/modules/utils/logoManagement";
import {addQueryParametersToRoute, createLoader} from "../../utils";
import {line_id} from "../..";
import {pictoNetwork} from "../network";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

export * from './line-form';

const lines_config = {
    section_name: 'schedule-section',
    lines_section_name: 'schedule-section__lines',
    home_content: '.schedule-lines__content',
    input_id: 'schedule_stop_area_lines_autocomplete-hidden',
    stop_area_type: 'stop_area'
}

export function displayLinesByModes() {
    const linesByModesContainer = document.querySelector(lines_config.home_content);
    if (linesByModesContainer.children.length > 0) {
        return;
    }

    window.Kisio.schedule_config?.home.modes.forEach(function (mode) {
        const modeName = mode.replace("physical_mode:", "").toLowerCase();
        const modeElement = document.createElement('div');
        modeElement.className = lines_config.section_name;
        modeElement.dataset.modeName = mode;

        modeElement.innerHTML =
            `<div class="schedule-section__mode" tabindex="0">
                <i role="presentation" class="ikisio ikisio-${modeName} schedule-section__mode-picto" aria-hidden="true"></i>
                <span class="schedule-section__mode-name">${Translator.trans(mode.toLowerCase())}</span>
            </div>
            <i role="presentation" class="ikisio ikisio-arrow-drop-down" aria-hidden="true"></i>`;
        linesByModesContainer.appendChild(modeElement);

        displayLinesEventListener(modeElement);
    });
}

export function pictoLines(lines, byNetwork = false) {
    let linesEl = byNetwork === true ? '<div class="schedule-section__lines-grid">' : '';

    lines.forEach(function (line) {
        let disruption = '';
        if (line.disruptions) {
            disruption = getDisruptionsSevirityById(line.disruptions, line.id);
            disruption = `<i role="presentation" class="ikisio ikisio-${disruption.image} ${disruption.image}" aria-hidden="true"></i>`;
        }

        let span = generateLogoFromConfig('lines', line.id, ` <span class="schedule-section__line-constrast" style="color: #${line.text_color}; background-color: #${line.color}">${line.code}</span>`, {classname: "schedule-section__line-constrast"} );

        linesEl +=
            `<div class="schedule-section__lines-item" tabindex="0" data-line-id="${line.id}">
                ${span}
                ${disruption}
            </div>`;
    });

    return linesEl + (byNetwork === true ? '</div>' : '');
}

export function removeLinesSection() {
    document.querySelectorAll(`.${lines_config.lines_section_name}`).forEach(function (item) {
        item.remove();
    });
}

export function hasHomeContent() {
    return document.querySelector(lines_config.home_content)?.children.length > 0
}

function displayLinesEventListener(modeElement) {
    modeElement.addEventListener('click', async function (e) {
        const linesContainer = document.querySelector(`.schedule-section__lines[data-mode-name="${e.currentTarget.dataset.modeName}"]`);
        if (linesContainer) {
            if (modeElement.classList.contains('show')) {
                linesContainer.classList.add('gl-hide');
                modeElement.classList.remove('show');
                return;
            }

            modeElement.classList.add('show');
            linesContainer.classList.remove('gl-hide');

            simplebarManagement();
            return;
        }
        modeElement.classList.add('show');

        // create lines by mode section
        const linesContainerEl = document.createElement('div');
        linesContainerEl.className = lines_config.lines_section_name;
        linesContainerEl.dataset.modeName = modeElement.dataset.modeName;
        linesContainerEl.innerHTML = createLoader();

        modeElement.after(linesContainerEl);
        await getLinesByFilters(modeElement.dataset.modeName)
            .then(function (linesList) {
                linesContainerEl.innerHTML = linesList;
                manageClickOnLine(linesContainerEl);
            });
        simplebarManagement();
    });

    modeElement.addEventListener('keyup', function(e) {
        if (e.keyCode == 32 || e.keyCode == 13) {
            JKisio(this).trigger('click');
        }
    });
}

function simplebarManagement() {
    let content = document.querySelector('#schedule_content');

    if (content.SimpleBar) {
        content.SimpleBar.unMount();

    }
    new SimpleBar(content);
}

async function getLinesByFilters(mode) {
    let result;
    const queryParams = {
        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
        public_transport_object_type: 'physical_modes',
        public_transport_object_id: mode,
        action: 'lines',
        count: Kisio.schedule_config.home.max_lines,
        path_filter: `physical_modes/${mode}`,
        disable_geojson: "true"
    };

    // filter by stop area
    const inputFormId = document.getElementById(lines_config.input_id).value;
    if (inputFormId && inputFormId.includes(lines_config.stop_area_type)) {
        queryParams.path_filter += `/stop_areas/${inputFormId}`;
    }

    let lines = [];
    // exclude some network and not filter on network_id !
    if(typeof window.Kisio.route_schedules_networks.excluded != 'undefined' && window.Kisio.route_schedules_networks.excluded.length > 0) {
        queryParams.forbidden_id = window.Kisio.route_schedules_networks.excluded;
    } else {
        // filter by network
        const dropdown = document?.getElementById("network_dropdown");
        const selectValue = dropdown?.querySelector('.network_dropdown__value');
        const selectedNetworkId = selectValue?.getAttribute('data-value');

        if (selectedNetworkId && selectedNetworkId !== 'all') {
            queryParams.path_filter += `/networks/${selectedNetworkId}`;
        }else if (window.Kisio.network_id !== '') {
            

            // // Second call for Kisio.route_schedules_networks.included 
            if(Kisio.route_schedules_networks.included !== '') {
                queryParams.filter = `network.id(${window.Kisio.network_id}) or network.id(${Kisio.route_schedules_networks.included})`
            }else {
                queryParams.path_filter += `/networks/${window.Kisio.network_id}`;
            }

        } 
    }

    // Get lines by section mode
    await JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered', queryParams),
        async: true,
        dataType: 'json',
        success: function (data) {
            if (!data.lines || data.lines.length === 0) {
                result = `<div class="schedule-section__lines-empty" tabindex="0"><span>${Translator.trans('schedule.no_lines')}</span></div>`;
            } else {
                result = linesByNetwork(data.lines);
            }
        },
        error: function(xhr, status, error) {
            result = `<span tabindex="0">${Translator.trans('schedule.form.error')}</span>`;
        }
    });

    return result;
}

function linesByNetwork(lines) {
    const dropdown = document?.getElementById("network_dropdown");
    const selectValue = dropdown?.querySelector('.network_dropdown__value');
    const linesByNetwork = {};

    // Permet d'ordoner les lignes dans le cas ou un network spécifiée et un autre est include
    if (window.Kisio.network_id !== '' && Kisio.route_schedules_networks.included !== '') {
        linesByNetwork[window.Kisio.network_id] = [];
        linesByNetwork[Kisio.route_schedules_networks.included] = [];
    }

    lines.forEach(item => {
        const networkId = item.network.id;
        const networkName = item.network.name;
        if (!linesByNetwork[networkId]) {
            linesByNetwork[networkId] = [];
        }
        linesByNetwork[networkId].push(item);
    });

    const useNetworkFilter = Kisio.schedule_config.home.filter_by_networks;
    let result = '';
    const selectedNetworkId = selectValue?.getAttribute('data-value');
    if (selectedNetworkId === "all" || !useNetworkFilter) {
        Object.keys(linesByNetwork).forEach(networkId => {
            if (useNetworkFilter) {
                if (linesByNetwork[networkId][0]?.network.name !== undefined) {
                    result += `<span class="schedule-section__lines-network-name">${linesByNetwork[networkId][0]?.network.name}</span>`;
                }
            }
            result += pictoLines(linesByNetwork[networkId], useNetworkFilter);
        });
    } else {
        const selectedNetworkLines = linesByNetwork[selectedNetworkId];

        if (!selectedNetworkLines || selectedNetworkLines.length === 0) {
            result = `<div class="schedule-section__lines-empty"><span>${Translator.trans('schedule.no_lines')}</span></div>`;
        } else {
            result = pictoNetwork(selectedNetworkId, linesByNetwork[selectedNetworkId][0]?.network.name) + pictoLines(selectedNetworkLines, useNetworkFilter);
        }
    }

    return result;
}

function manageClickOnLine(linesElement) {
    linesElement.querySelectorAll('.schedule-section__lines-item')?.forEach(function (item) {
        item.addEventListener('click', function (e) {
            displayLineFormById(e.currentTarget.dataset.lineId);

            const queryParams = {};
            queryParams[line_id] = e.currentTarget.dataset.lineId;
            addQueryParametersToRoute(queryParams);
        });
        item.addEventListener('keyup', function(e) {
            if (e.keyCode == 32 || e.keyCode == 13) {
                JKisio(this).trigger('click');
            }
        });
    });
}
