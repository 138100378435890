import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';

const LIST_DISRUPTIONS_SHOW = ['disrupt'];

export function getDisruptions(departureObject) {
    const messageElements = document.createElement('div');
    messageElements.className = 'schedule-line-departures__disruptions';

    let arrayOfDisruption = [];

    departureObject.disruptions?.forEach(function (d) {
        let level_severity = getDisruptionSevirity(d);

        d.impacted_objects.forEach(function () {
            arrayOfDisruption.push(level_severity)
        });
    });

    if (!arrayOfDisruption.includes('disrupt')) {
        return messageElements;
    }

    let span = document.createElement('span');
    
    const routeParams = window.location.pathname.split('/')
    let link = `${window.location.origin}/${routeParams[1]}/${routeParams[2]}/traffic?line=${encodeURIComponent(departureObject.id)}`;

    let innerHTML = "<i class='ikisio ikisio-information-important'></i>" + Translator.trans('schedule.result.disruptions.warning') + ' ';
    innerHTML += `<a href="${link}">${Translator.trans('schedule.result.disruptions.warning_link')}</a>`;

    span.innerHTML = innerHTML;
    messageElements.appendChild(span);    

    return messageElements;
}

function idExists(collection, id) {
    return collection.some(item => item.id === id);
}

export function manageDisruptionsEventListeners() {
    const disruptions = document.querySelectorAll('.disruption__network-content');

    [...disruptions].forEach(function (disruption) {
        const messageEl = disruption.querySelector('.disruption__message');
        const messageStyle = getComputedStyle(messageEl);
        if (parseInt(messageStyle.lineHeight.replace('px', '') ) * 2 < messageEl.offsetHeight) {
            disruption.classList.add('hide-txt');
            const showMore = disruption.querySelector('.disruption__network-content-show');
            showMore.classList.remove('gl-hide');
            showMore.addEventListener('click', function () {
                window.dispatchEvent(new Event('resize'));
                if (disruption.classList.contains('hide-txt')) {
                    disruption.classList.remove('hide-txt');
                    return;
                }
                disruption.classList.add('hide-txt');
            });
        }
    });
}

const severityClass = {
    'warning': 'information',
    'disrupt': 'information-important',
    'information': 'information-outline'
}

function getDisruptionsSevirity(disruptions) {
    const arrayOfDisruption = [];
    let level_severity = '';
    
    disruptions.forEach(function (d) {
        level_severity = getDisruptionSevirity(d);

        d.impacted_objects.forEach(function (impactedObject) {
            arrayOfDisruption[impactedObject.pt_object.id] = {
                'cause' : d.cause,
                'image': severityClass[level_severity]
            }
        });
    });

    return arrayOfDisruption;
}

function getDisruptionSevirity(disruption)
{
    let severity = disruption.severity.effect;
    let level_severity = null;

    switch (severity) {
        case 'NO_SERVICE':
            level_severity = 'disrupt';
            break;
        case 'SIGNIFICANT_DELAYS':
        case 'REDUCED_SERVICE':
        case 'DETOUR':
        case 'ADDITIONAL_SERVICE':
        case 'MODIFIED_SERVICE':
        case 'UNKNOWN_EFFECT':
        case 'STOP_MOVED':
            level_severity = 'warning';
            break;
        case 'OTHER_EFFECT':
        default:
            level_severity = 'information';
            break;
    }

    return level_severity;
}

export function getDisruptionsSevirityById(disruptions, lineId) {
    const disruptionsSevirity = getDisruptionsSevirity(disruptions);

    return disruptionsSevirity[lineId];
}

function createMessage(disruption, message) {
    let level = disruption.severity.level;


    if(typeof level == 'undefined') {
        level = getDisruptionSevirity(disruption);
    }

    if(LIST_DISRUPTIONS_SHOW.indexOf(level) === -1) { // do not show
        return '';
    }

    return `<div class="disruption__network-container ${level}">
                <div class="disruption__network-content ${level}">
                    <i role="presentation" class="ikisio ikisio-${level} disruption__network-picto" aria-hidden="true"></i>
                    <div class="disruption__message">
                        <span>${message.text}</span>
                    </div>
                    <span class="disruption__network-content-show gl-hide">
                        <i role="presentation" class="ikisio ikisio-arrow-drop-down" aria-hidden="true"></i>
                    </span>
                </div>
            </div>`;
}
