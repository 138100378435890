import {
    displayLineFormById,
    displayLinesByModes,
    hasHomeContent,
    line_form_layer,
    removeLinesSection
} from "./module/line";
import {addSelectedStopToMap, manageScheduleLayers} from "./module/map";
import {refreshModesListByNetworkId, resetAllModes, resetModesList, updateModesByStopAreaId} from "./module/modes";
import { showNetWorks } from "./module/network";
import {
    addQueryParametersToRoute,
    getAllQueryParameters,
    getQueryParamByName,
    removeQueryParametersFromRoute
} from "./utils";

export const line_id = 'schedule[line_id][autocomplete-hidden]';
export const line_name = 'schedule[line_id][autocomplete]';
export const stop_area_id = 'schedule[stop_area_lines][autocomplete-hidden]';
export const stop_area_name = 'schedule[stop_area_lines][autocomplete]';
export const arrival_stop_area_id = 'schedule[arrival_stop_area][autocomplete-hidden]';
export const arrival_stop_area_name = 'schedule[arrival_stop_area][autocomplete]';
export const schedule_types = {
    departure: {
        id: stop_area_id,
        name: stop_area_name
    },
    arrival: {
        id: arrival_stop_area_id,
        name: arrival_stop_area_name
    },
    line: {
        id: line_id,
        name: line_name
    }
}

export const departure_arrival_event = 'departure_arrival_changed';

const stopAreaType = 'stop_area:';
const lineType = 'line:';
const inputId = '#schedule_stop_area_lines_autocomplete';
const inputHiddenId = '#schedule_stop_area_lines_autocomplete-hidden';
const useNetworkFilter = Kisio.schedule_config.home.filter_by_networks;

document.addEventListener('DOMContentLoaded', function () {
    // initialize autocomplete
    JKisio(function() {
        Kisio.Callbacks.autocomplete();
    });

    if (useNetworkFilter) {
        showNetWorks();
    }

    document.body.addEventListener('schedule_container_displayed', function () {
        document.title = Translator.trans('schedule.title_page');
        Kisio.section_active = 'schedule';

        document.dispatchEvent(new CustomEvent("clear_map", {detail: {clean_data: false}}));
        displayLinesByModes();
        displayTargetModuleByRoute();

        document.body.dispatchEvent(new CustomEvent("display_layers_section_event", {detail: {section: "schedule"}}));
    });

    // listeners click on autocomplete
    document.body.addEventListener('schedule_autocomplete_selected_item', function (e) {
        if (!e.detail?.value) {
            return;
        }

        displayTargetModuleByInputValues(e.detail);
    });

    document.body.addEventListener(`${inputId}-cleared`, function () {
        resetModesList();
        removeLinesSection();
        removeAllParametersFromRoute();
    });
});

function displayTargetModuleByRoute() {
    // display modes by selected stop area
    const stopAreaId = getQueryParamByName(schedule_types.departure.id);

    if (stopAreaId) {
        updateModesByStopAreaId(stopAreaId);
    }

    // display schedule line
    const lineId = getQueryParamByName(schedule_types.line.id);

    if (lineId) {
        displayLineFormById(lineId);
    }

    // manage route parameters
    document.body.addEventListener(departure_arrival_event, function (e) {
        Object.keys(e.detail).forEach(function (key) {
            const params = {};
            params[schedule_types[key].id] = e.detail[key].id;
            params[schedule_types[key].name] = e.detail[key].title;

            addQueryParametersToRoute(params);
            addSelectedStopToMap(e.detail[key]);
        });
    });

    // click on back link
    document.body.addEventListener('schedule_back_clicked', function (e) {
        if (e.detail?.layer_section === line_form_layer) {
            const inputVal = JKisio(inputId).val();

            if (inputVal === '') {
                JKisio(inputHiddenId).val(null);

                // reset mode by network
                if (useNetworkFilter) {
                    const networkId = document.querySelector('.network_dropdown__value')?.dataset.value;
                    refreshModesListByNetworkId(networkId);
                } else {
                    resetModesList();
                }

                removeLinesSection();
                removeAllParametersFromRoute();
            }

            removeQueryParametersFromRoute([arrival_stop_area_id, arrival_stop_area_name]);
        }
    });
}

function displayTargetModuleByInputValues(object) {
    if (object.value.includes(stopAreaType)) {
        updateModesByStopAreaId(object.value);

        const queryParams = {};
        queryParams[schedule_types.departure.id] = object.value;
        queryParams[schedule_types.departure.name] = object.label;
        addQueryParametersToRoute(queryParams);

        return;
    }

    if (object.value.includes(lineType)) {
        displayLineFormById(object.value);

        const queryParams = {};
        queryParams[schedule_types.line.id] = object.value;
        queryParams[schedule_types.line.name] = object.label;
        addQueryParametersToRoute(queryParams);
    }
}

function removeAllParametersFromRoute() {
    const result = [];
    Object.values(schedule_types).forEach(obj => {
        result.push(obj.id);
        result.push(obj.name);
    });
    removeQueryParametersFromRoute(result);
}
