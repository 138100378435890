import L from 'pnp_core_bundle/lib/leaflet.js';

const vehiclePositionMarker = [];
const parameters = {
    urlImages : '/bundles/canaltppnpcore/images/',
    clear_interval_event: 'schedule_back_clicked'
};

let intervalDirection;
let observer = null;

export async function displayVehiclePositionByRouteId(lineId, stopPointId) {
    if (!window.Kisio.schedule_config.vehicle_position.activate) {
        return;
    }

    if (intervalDirection) {
        clearInterval(intervalDirection);
    }

    // first call
    apiCall(lineId, stopPointId);

    // refresh every interval time
    intervalDirection = setInterval(function () {
        apiCall(lineId, stopPointId);
    }, window.Kisio.schedule_config.vehicle_position.interval);

    document.body.addEventListener(parameters.clear_interval_event, function () {
        // clear vehicle-position
        if (intervalDirection) {
            clearInterval(intervalDirection);
        }
    });
}

async function apiCall(lineId, stopPointId) {
    try {
        JKisio.ajax({
            url: Routing.generate('schedule_vp_stop_point', {
                lineId: lineId,
                stopPointId: stopPointId,
                type_product: window.Kisio.type_product
            }),
            async: true,
            success: function (data) {
                if (data.length > 0) {
                    for (const [key, value] of Object.entries(vehiclePositionMarker)) {
                        if (hasVehiclePosition(value.id, data[0]?.vehicle_journey_positions)
                            || value.iteration > window.Kisio.schedule_config.vehicle_position.max_history
                        ) {
                            window.leaflet.map.removeLayer(value.marker);
                            delete vehiclePositionMarker[key];
                        } else {
                            const origin = L.icon({
                                iconUrl: parameters.urlImages + 'modes/vehicle-no-signal.svg',
                                iconSize: 50,
                                popupAnchor: [170, 90]
                            });
                            value.marker.setIcon(origin);
                            vehiclePositionMarker[key].iteration = vehiclePositionMarker[key].iteration ?? 0;
                            vehiclePositionMarker[key].iteration++;
                            // createPopup(value.marker, data[0].line, null, true);
                        }
                    }

                    for (const result in data) {
                        displayPicto(data[result]);
                    }
                }
            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });
    } catch (e) {
        console.log(e);
    }
}

function displayPicto(result) {
    if (!result.vehicle_journey_positions) {
        return;
    }

    result.vehicle_journey_positions?.forEach(function (journey_position) {
        if (journey_position.coord && journey_position.coord.lat && journey_position.coord.lon) {
            const origin = L.icon({
                iconUrl: parameters.urlImages + 'modes/vehicle.svg',
                iconSize: 50,
                popupAnchor: [170, 90]
            });

            const positionMarker = L.marker(
                [journey_position.coord.lat, journey_position.coord.lon],
                {icon: origin, layerType: 'vehicle-position-marker', keyboard: false, layer_section: ['schedule_line_departures']}
            );
            // createPopup(positionMarker, result.line, journey_position);
            // positionMarker?.addTo(window.leaflet.map);
            Kisio.layerGroup.schedule.scheduleLayers.addLayer(positionMarker);
            if (Kisio.section_active == "schedule") {
                Kisio.layerGroup.schedule.scheduleLayers.addTo(window.leaflet.map);
            }
            vehiclePositionMarker.push({id: journey_position.vehicle_journey.id, marker: positionMarker});
        }
    });
}

function createPopup(marker, line, vehicle_position = null, no_signal = false) {
    const el = document.createElement('div');
    const mode = line.physical_modes[0].name;
    let dom = `<div class="vehicle-position-popup__header"><span>${mode}</span>` +
        `<span class="vehicle-position-popup__code-line" style="background-color: #${line.color}; color: #${line.text_color}">${line.code}</span></div>`;

    if (no_signal) {
        dom += `<div class="vehicle-position-popup__body"><span class="vehicle-position-popup__no-signal">${Translator.trans('schedule.occupancy.no_signal')}</span></div>`;
    } else {
        dom += `<div class="vehicle-position-popup__body"><div class="vehicle-position-popup__direction">` +
            `<span>${Translator.trans('next_departures.table.headers.direction')}</span>` +
            `<span>${vehicle_position.vehicle_journey.name}</span></div>`;
            if (vehicle_position.occupancy != "EMPTY") {
                dom += `<div class="vehicle-position-popup__occupancy" ><div>` +
            `<img src="/bundles/canaltppnpcore/images/realtime.gif" alt="realtime" /> ` +
            `<img src="/bundles/canaltpjourney/images/${vehicle_position.occupancy}.svg"
            alt="occupancy"/></div><div class="vehicle-position-popup__occupancy-info">` +
            `<span>${Translator.trans("schedule.occupancy.popup_title")}</span>` +
            `<span>${Translator.trans("schedule.occupancy." + vehicle_position.occupancy.toLowerCase() + ".title").toLowerCase()}</span></div></div></div>`;
        }
    }

    el.innerHTML = dom;
    marker.on('mouseover', function () {
        this.openPopup();
    });
    marker.on('mouseout', function () {
        this.closePopup();
    });
}

function hasVehiclePosition(id, vehiclesPosition) {
    for (const [key, value] of Object.entries(vehiclesPosition)) {
        if (id === value.vehicle_journey.id) {
            return true;
        }
    }
    return false;
}
