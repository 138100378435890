import { addNextBackPageListeners, addNavigationControlsListeners } from './core/event-listeners';
import { time_table_consts } from './core/constants';
import {callApiTimetable} from "../api";
import {createLoader, informationMessage} from "../../utils";
import {createSelect, manageClickOnSelect} from "../select";
import {downloadTimeTableButton, downloadEventListeners} from "./download";
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { displayScheduleLineDepartures } from '../line-departures';

const timetable_config = {
    class: 'schedule-line-departures__timetable',
    datepicker_class: 'timetable__form select'
};

export function createTimeTableSection(departureObject) {
    const timetableSectionEl = document.createElement('div');
    timetableSectionEl.className = timetable_config.class;

    timetableSectionEl.appendChild(createHeader());

    const timetableEl = document.createElement('div');
    timetableEl.className = 'timetable table';
    timetableSectionEl.appendChild(timetableEl);
    timetableEl.innerHTML = createLoader();

    createTimeTable(departureObject, timetableEl);

    (new SimpleBar(timetableSectionEl));

    return timetableSectionEl;
}

function createHeader() {
    const headerEl = document.createElement('div');
    headerEl.className = 'timetable';
    headerEl.innerHTML = `<span class="timetable__title" tabindex="0">${Translator.trans('timetables.title')}</span>
        <span class="timetable__sub-title" tabindex="0">${Translator.trans('timetables.sub_title')}</span>`;

    return headerEl;
}

function createTimeTablesForm(departureObject, dataIfNextDepartures) {
    const datepickerEl = document.createElement('div');
    datepickerEl.className = timetable_config.datepicker_class;
    datepickerEl.innerHTML =
        `<div id="timetable-datepicker" class="input-group date" data-provide="datepicker">
             <span class="input-group-addon icon-picker-input">
                <em role="presentation" class="align-middle ikisio ikisio-calendar"></em>
            </span>
            <input name="timetable_datepicker" type="text" class="form-control" readonly="readonly" value="${departureObject.datetime ?? Translator.trans('timetables.today')}">
        </div>`;

    datepickerEl.appendChild(createSelect(dataIfNextDepartures));

    return datepickerEl;
}

async function createTimeTable(departureObject, timetableEl) {
    const query = {
        lineId: departureObject.line_id,
        stopPointId: departureObject.stop_point ?? 'undefined'
    };
    if (departureObject.datetime) {
        query.from_datetime = departureObject.datetime;
        query.opening_time = departureObject.opening_time;
    }

    callApiTimetable(query).then(function (data) {
        timetableEl.innerHTML = '';
        const timetables = document.createElement('div');
        timetables.className = 'timetable__list';
        let dataIfNextDepartures = dataIfNextDeparturesFilter(departureObject, data);
        timetableEl.appendChild(createTimeTablesForm(departureObject, dataIfNextDepartures));
        timetableEl.appendChild(createTimeTablesByDirection(dataIfNextDepartures, departureObject, timetables));
        initTimeTableListeners();
        manageSelect(timetableEl);
        manageDatepicker(departureObject);

        if (data && data.length === 0) {
            timetableEl.appendChild(informationMessage(Translator.trans('timetables.empty')))
            return;
        }
        
        timetableEl.appendChild(downloadTimeTableButton(departureObject));
        downloadEventListeners();

        Array.prototype.forEach.call(
            document.querySelectorAll('.timetable_paginate'),
            (el) => new SimpleBar(el)
        );

    }).catch(function(error) {
        timetableEl.innerHTML = '';
        timetableEl.appendChild(informationMessage(Translator.trans('timetables.error')));
    });
}

function dataIfNextDeparturesFilter(departureObject, data) {
    let result = [];
    data.forEach(function (timetable, index) {
        const directionId = timetable.direction;
        let foundDirection = departureObject.directions.find((item) => {
            return item.id == directionId
        });
        if (directionsHasDuplicateStops(departureObject.directions, directionId)) {
            foundDirection = departureObject.directions.find((item) => {
                return item.id == directionId && item.name === timetable.display_informations.direction
            });
        }
        
        if (!foundDirection) {
            console.log('Timetable not found : ' + directionId);
            return;
        }

        result.push(timetable);
    })

    return result;
}

function createTimeTablesByDirection(data, departureObject, parentEl) {
    data.forEach(function (timetable, index) {
        const directionId = timetable.route.direction;
                                
        const firstDeparture = timetable.first_datetime.date_time.substr(9, 2) + 'h' + timetable.first_datetime.date_time.substr(11, 2);
        const lastDeparture = timetable.last_datetime.date_time.substr(9, 2) + 'h' + timetable.last_datetime.date_time.substr(11, 2);

        const className = !parentEl.innerHTML ? 'timetable__by-direction' : 'timetable__by-direction gl-hide';
        parentEl.innerHTML += `<div class="${className}" data-direction="${directionId.name}" data-direction-id="${directionId.id}">
                    <div class="first-last-departure">
                        <span tabindex="0">${Translator.trans('schedule.opening_time')}${firstDeparture}</span>
                        <span tabindex="0">${Translator.trans('schedule.closing_time')}${lastDeparture}</span>
                    </div>` + timetable.content + '</div>';
    });

    return parentEl;
}

function directionsHasDuplicateStops(data, directionId) {
    const idSet = new Set();
    for (const item of data) {
        if (directionId == item.id) {
            if (idSet.has(item.id)) {
                return true;
            }
            idSet.add(item.id);
        }
    }

    return false;
}

function manageSelect(timetableEl) {
    manageClickOnSelect(document.querySelector('.timetable__form > details'), 'timetable_direction_changed');
    document.body.addEventListener('timetable_direction_changed', function (e) {
        timetableEl.querySelectorAll('.timetable__by-direction').forEach(function (timetable) {
            if (timetable.dataset.direction === e.detail.selected_input.title) {
                timetable.classList.remove('gl-hide');
                return;
            }

            timetable.classList.add('gl-hide');
        });
    });
}

function initTimeTableListeners () {
    const timetablesElement = document.querySelectorAll(time_table_consts.main_class);

    timetablesElement.forEach(function (timetableElement) {
        addNextBackPageListeners(timetableElement);
        addNavigationControlsListeners(timetableElement);
    });
}

function manageDatepicker(departureObject) {
    // initalize datepicker
    JKisio("#timetable-datepicker")
        .bootstrapDP({
            setDate: departureObject.datetime ? departureObject.datetime : new Date(),
            startDate: "now",
            language: window.Kisio.locale,
            autoclose: true
        })
        .on('change', function (e) {
            e.target.dataset.fromDatetime = e.target.value;

            const selectedChoices = {
                arrival: departureObject.arrival,
                departure: departureObject.departure
            }
            // used in boostrapDB bundle 
            let datetimeValue = (e.target.value === Translator.trans('journey.form.today')) ? null : e.target.value;
            displayScheduleLineDepartures({
                ...departureObject,
                selectedChoices, 
                datetime: datetimeValue
            });
        });
}

function getDateByTimeStamp(timeStamp) {
    const date = new Date(timeStamp);

    return `${date.getFullYear()}` +
        `${(date.getMonth().toString().length  === 1 ? '0' : '') + (date.getMonth() + 1).toString()}` +
        `${(date.getDate().toString().length  === 1 ? '0' : '') + date.getDate().toString()}`;
}
