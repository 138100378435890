import {getMapPosition} from "../../utils";
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

export function displayLineOnMap(line, layerSectionName) {
    executeIfMapExist(() => {
        if (!line || !line.geojson || line.geojson.coordinates.length === 0) {
            return;
        }

        const options = {
            style: {
                color: "#" + line.color,
                weight: 5,
                opacity: 1
            },
            line_id: line?.id,
            physical_mode: line?.physical_modes[0]?.id,
            network_id: line?.network?.id,
            layer_section: [layerSectionName]
        };

        const geoJsonLine = L.geoJSON(line.geojson, options);

        if (!Kisio.layerGroup.schedule.hasOwnProperty(layerSectionName)) {
            Kisio.layerGroup.schedule[layerSectionName] = new L.layerGroup();
        }

        Kisio.layerGroup.schedule[layerSectionName].addLayer(geoJsonLine);

        if (Kisio.section_active == "schedule") {
            Kisio.layerGroup.schedule[layerSectionName].addTo(window.leaflet.map);
            window.leaflet.map.fitBounds(geoJsonLine.getBounds(), getMapPosition());
        }

        displayLineRoutes(line?.routes, line.color, layerSectionName, 'top');
    });

}

export function displayStopPointsOnMap(stopPoints, lineId, lineObject, layerSectionName) {
    executeIfMapExist(() => {
        if (!stopPoints || stopPoints.length === 0) {
            return;
        }

        const group = L.featureGroup();
        const listStopPoints = [];
        const nameCount = {};

        stopPoints.forEach(stopPoint => {
            nameCount[stopPoint.name] = (nameCount[stopPoint.name] || 0) + 1;
        });

        stopPoints.forEach(function (stopPoint) {
            if (!stopPoint.coord || !stopPoint.coord.lat || !stopPoint.coord.lon) {
                return;
            }

            const label = nameCount[stopPoint.name] > 1 ? stopPoint.label : stopPoint.name;

            if(listStopPoints.indexOf(label) !== -1) {
                return;
            }

            listStopPoints.push(label);

            const circleMarker = L.circleMarker(
                [stopPoint.coord.lat, stopPoint.coord.lon], {
                    radius: 5,
                    width: 0.1,
                    color: '#' + lineObject.color,
                    fillOpacity: 1,
                    weight: 7,
                    line_id: lineId,
                    layer_section: [layerSectionName]
                }
            ).addTo(group);

            circleMarker
                .on('mouseover', function (e) {
                    const event = new CustomEvent("show_line_in_popup_event", {
                        detail: { previousEvent: e, line: lineObject, object: stopPoint}
                    });
                    document.dispatchEvent(event);
                })
        });

        if (!Kisio.layerGroup.schedule.hasOwnProperty(layerSectionName)) {
            Kisio.layerGroup.schedule[layerSectionName] = new L.layerGroup();
        }

        Kisio.layerGroup.schedule[layerSectionName].addLayer(group);

        if (Kisio.section_active == "schedule") {
            Kisio.layerGroup.schedule[layerSectionName].addTo(window.leaflet.map);
            window.leaflet.map.fitBounds(group.getBounds(), getMapPosition());
        }
    });
}

function displayLineRoutes(routes, lineColor, layerSectionName) {
    routes?.forEach(function (route) {
        const name = route.direction?.name || route.name;
        const coord = route.direction?.stop_area.coord || route.coord;
        createToolTip(name, 'tooltip__terminus', 'top', lineColor, coord.lat, coord.lon, [layerSectionName]);
    });
}

export function displayDepartureArrivalOnMap(stopPoints, lineColor) {
    const departureArrival = stopPoints.length >= 2 ? [
        { ...stopPoints[0], className: "tooltip__item_departure" },
        { ...stopPoints[stopPoints.length - 1], className: "tooltip__item_arrival" }
    ] : [];
    departureArrival?.forEach(function (route) {
        const name = route.direction?.name || route.name;
        const coord = route.direction?.stop_area.coord || route.coord;
        createToolTip(name, route.className, 'bottom', lineColor, coord.lat, coord.lon, 'schedule_line');
    });
}


export function addSelectedStopToMap(input) {
    clearStopToolTip(`tooltip__${input.name}`);
    createToolTip(input.title, `tooltip__${input.name}`, 'bottom', input.color, input.lat, input.lon, 'schedule_line');
}

export function clearStopToolTip(inputType) {
    executeIfMapExist(() => {
        let layers = Kisio.layerGroup.schedule.schedule_line._layers;
        for (var layerId in layers) {
            if (layers.hasOwnProperty(layerId)) {
                var layer = layers[layerId];
                if(layer.options.className === inputType) {
                    delete layers[layerId];
                    layer.removeFrom(window.leaflet.map);
                }
            }
        }
    });
}

function createToolTip(name, className, direction, color, lat, lon, layerSectionName) {
    executeIfMapExist(() => {
        let toolTip = L.tooltip({className: className, direction: direction, layer_section: layerSectionName})
            .setLatLng([lat, lon])
            .setContent(`<span class="tooltip__terminus-content" style="color: #000000 !important;border: 4px solid #${color}">${name}</span>`);

        if (!Kisio.layerGroup.schedule.hasOwnProperty(layerSectionName)) {
            Kisio.layerGroup.schedule[layerSectionName] = new L.layerGroup();
        }

        Kisio.layerGroup.schedule[layerSectionName].addLayer(toolTip);

        if (Kisio.section_active == "schedule") {
            Kisio.layerGroup.schedule[layerSectionName].addTo(window.leaflet.map);
        }
    });
}
