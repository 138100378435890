import {generateLogoFromConfig} from "pnp_core_bundle/modules/utils/logoManagement";
import { removeLinesSection } from "../line";
import {refreshModesList, refreshModesListByNetworkId, resetModesList} from "../modes";
import { callApiPtObjects } from "../api";

export function pictoNetwork(networkId, networkName) {
    return `<h4 role="presentation" class="schedule-section__lines-network">${
        generateLogoFromConfig(
            'networks',
            networkId,
            Kisio.networks[networkId] ? Kisio.networks[networkId]['name'] ?? networkId : networkName
        )
    }</h4>`;
}

export async function showNetWorks() {
    const networksList = await fetchNetworks();
    const select = document.getElementById("network_dropdown");

    const valueSpan = select.querySelector('.network_dropdown__value');
    const networks = {
        ['all']: { name: Translator.trans('line_schedule.result.all_networks') },
        ...networksList
    };

    const optionsDiv = select.querySelector('.network_dropdown__options');
    if (!optionsDiv) {
        createAndAppendOptions(select, networks, valueSpan);
    }

    select.addEventListener('click', () => toggleDropdownList(select));
}

function toggleDropdownList(selectElement) {
    if (selectElement.classList.contains('list_open')) {
        selectElement.classList.remove('list_open');
        return;
    }
    selectElement.classList.add('list_open');
}

function createAndAppendOptions(select, networks, valueSpan) {
    const optionsContainer = document.createElement('div');
    optionsContainer.className = 'network_dropdown__options';
    select.appendChild(optionsContainer);

    Object.keys(networks).forEach(key => {
        const optionDiv = createOptionDiv(networks, key);
        optionsContainer.appendChild(optionDiv);

        optionDiv.addEventListener('click', () => handleOptionClick(optionDiv, networks, valueSpan, optionsContainer));
    });
}

function createOptionDiv(networks, key) {
    const network = networks[key];
    const optionDiv = document.createElement('div');
    optionDiv.className = 'network_option';
    optionDiv.textContent = network?.name;
    optionDiv.setAttribute('data-id', key);
    return optionDiv;
}

async function handleOptionClick(optionDiv, networks, valueSpan, optionsContainer) {
    const networkId = optionDiv.getAttribute('data-id');
    const network = networks[networkId];
    
    if (networkId === "all") {
        resetModesList();
        removeLinesSection();
    }

    refreshModesListByNetworkId(networkId);

    updateValueSpan(valueSpan, network, networkId);
    updateSelectedOption(optionsContainer, optionDiv);
}

function updateValueSpan(valueSpan, network, networkId) {
    valueSpan.textContent = network.name;
    valueSpan.setAttribute('data-value', networkId);
}

function updateSelectedOption(optionsContainer, selectedOptionDiv) {
    optionsContainer.querySelectorAll('.network_option').forEach(option => {
        option.classList.remove('selected');
    });
    selectedOptionDiv.classList.add('selected');
}

async function fetchNetworks() {
    return new Promise((resolve, reject) => {
        JKisio.ajax({
        url: Routing.generate("r_networks_by_ids", {
            type_product:
                window.Kisio.type_product !== undefined
                ? window.Kisio.type_product
                : "",
        }),
        dataType: "json",
        success: function (result) {
            if (Kisio.network_id !== '') {
                let res = [];
                res[Kisio.network_id] = result[Kisio.network_id];

                if(Kisio.route_schedules_networks.included !== '') {
                    res[Kisio.route_schedules_networks.included] = result[Kisio.route_schedules_networks.included];
                }

                resolve(res);
            }
            resolve(result);
        },
        error: function (error) {
            reject(error);
        },
        });
    });
}
