import {pictoLines} from "../line";
import {informationMessage, transformDateTime} from "../../utils";
import {BookmarkStarElementBuilder} from "bookmark_bundle/components/bookmark-star/bookmark-star-element";
import {NavitiaBuilder} from "bookmark_bundle/builder/navitia/navitia-builder";
import {callApiTerminusSchedule, callApiNextDepartures} from "../api";

const card_config = {
    class: 'schedule-line-departures__card',
    departures_class: 'departures',
    header_class: 'header',
    journey_section_type: ['public_transport', 'on_demand_transport'],
    date_span : 'departures__date',
    back_listener: 'schedule_back_clicked',
    interval_listener: 'schedule_back_clicked',
    departure_day_el: null
};

export async function createDeparturesCard(journeys, departureObject) {
    const card = document.createElement('div');
    card.className = card_config.class;

    card.appendChild(createHeaderBloc(departureObject));
    let a = await createNextDeparturesBloc(journeys, departureObject);

    card.appendChild(a);

    return card;
}

function createHeaderBloc(departureObject) {
    const resumeLine = document.createElement('div');
    resumeLine.className = card_config.header_class;

    let params;
    let type;
    let navitiaBuilder = new NavitiaBuilder();
     type = "stop_area_lines_direction";
     params = navitiaBuilder.stopAreas().setStopArea(
        departureObject.departure.stop_area,
        departureObject.line_id,
        departureObject.commercial_mode.id,
        departureObject.code,
        departureObject.color,
        departureObject.text_color,
        departureObject.network.id,
        departureObject.departure.stop_area_name,
        null,
        null,
    ).getNavitiaObject();

    let bookmarkStar = new BookmarkStarElementBuilder(
        {
            id: "anid",
            type: "stop_area_lines_direction",
            params: params,
            className: "fav-star fav-star-add fav-schedule",
        });

    let bookmarkHtml = bookmarkStar.buildHtml();

    resumeLine.innerHTML =
        `<div class="header__resume">
            <i role="presentation" class="ikisio ikisio-${departureObject.physical_modes[0].name.toLowerCase()}" aria-hidden="true"></i>
            ${pictoLines([departureObject])}
            ${bookmarkHtml}
        </div>
        <span>
            <strong tabindex="0">${departureObject.departure.stop_area_name}</strong> ${Translator.trans('next_departures.to')} 
            <strong tabindex="0">${departureObject.arrival.stop_area_name}</strong>
        </span>`;

    return resumeLine;
}

async function createNextDeparturesBloc(journeys, departureObject) {
    const nextDepartures = document.createElement('div');
    nextDepartures.className = card_config.departures_class;

    const span = document.createElement('span');
    span.className = 'departures__title';
    span.innerText = Translator.trans('next_departures.title');

    const date =  departureObject.datetime !== undefined ? departureObject.datetime : ' ' + Translator.trans('timetables.today');
    card_config.departure_day_el = document.createElement('span');
    card_config.departure_day_el.className = card_config.date_span;
    card_config.departure_day_el.innerText = date;

    nextDepartures.appendChild(span);
    nextDepartures.appendChild(card_config.departure_day_el);

    if (!journeys || journeys.length === 0) {
        nextDepartures.appendChild(informationMessage(Translator.trans('line_schedule.result.no_stop_times')));
        return nextDepartures;
    }
    if(Kisio.enabled_terminus_schedule_for_departure) {
        let departures = await departuresFromSchedule(journeys, departureObject);

        departures.forEach((departure) => {
            nextDepartures.appendChild(departure);
        });

        return nextDepartures;
    }

    journeys?.every(function (journey, key) {
        if (key > 2) {
            return false;
        }
        nextDepartures.appendChild(departureFromJourney(journey, key, departureObject.datetime));
        return true;
    });

    departuresCardRefresh(departureObject);

    return nextDepartures;
}

function departuresCardRefresh(departureObject, query = null, departures = null) {
    if (!departureObject.datetime) {
        const refreshInterval =  setInterval(() => {
            if (query) {
                refreshDeparturesFromSchedule(query, departures, departureObject.datetime);
            } else {
                refreshDeparturesFromJourney(departureObject);
            }
        }, 60000);

        document.body.addEventListener(card_config.back_listener, function () {
            clearInterval(refreshInterval);
        });

        document.body.addEventListener(card_config.interval_listener, function () {
            clearInterval(refreshInterval);
        });
    } else {
        document.body.dispatchEvent(new CustomEvent(card_config.interval_listener));
    }
}

function getUniqRoutesFromJourneys(journeys)
{
    var uniqueRoutes = [];

    journeys.forEach(function(journey) {
        const PtSection = getPtSection(journey.sections);
        const routeId = PtSection?.links.find((link) => link.type === 'route')?.id;
        if(uniqueRoutes.indexOf(routeId) === -1) {
            uniqueRoutes.push(routeId);
        }
    });
    return uniqueRoutes;
}

async function departuresFromSchedule(journeys, departureObject) {
    let routes = getUniqRoutesFromJourneys(journeys);
    const PtSection = getPtSection(journeys[0].sections);
    // const routeId = PtSection?.links.find((link) => link.type === 'route')?.id;
    const fromId = PtSection?.from?.stop_point?.id;
    let departures = [];
    const query = {
        routesString: routes.join(";"),
        stopPointId: fromId,
        opening_time: departureObject.opening_time,
    };
    if (departureObject.datetime) {
        query.dateTime = departureObject.datetime;
    }
    let dates = await callApiTerminusSchedule(query);
    let i=0;

    for (let datetime in dates)  {
        i++;

        let direction = dates[datetime]['direction'];

        if(i >3 ) {
           break;
        }

        let diffHour = calcdiffHour(datetime, i === 0);
        let departure = createDeparture();
        departure.appendChild(createDirection(direction));
        departure.appendChild(createDepartureTime(datetime, diffHour, dates[datetime]['data_freshness'] === 'realtime', departureObject.datetime));

        departures.push(departure);
    }

    departuresCardRefresh(departureObject, query, departures);

    return departures;
}

function departureFromJourney(journey, index, departureObjectDatetime) {
    const PtSection = getPtSection(journey.sections);

    const datetime = PtSection.departure_date_time;
    let diffHour = calcdiffHour(datetime, index === 0);

    let departure = createDeparture();
    departure.appendChild(createDirection(PtSection?.display_informations.direction));

    departure.appendChild(createDepartureTime(datetime, diffHour, PtSection.data_freshness === 'realtime', departureObjectDatetime));

    return departure;
}

function createDeparture()
{
    const departure = document.createElement('div');
    departure.className = 'departures__item';
    departure.setAttribute('tabindex', '0');
    return departure;
}

function createDirection(directionName)
{
    const direction = document.createElement('span');
    direction.classList.add('direction');
    direction.innerText = Translator.trans('next_departures.direction') + directionName;
    return direction;
}

function updateDirection(element, directionName)
{
    let directionEl = element?.querySelector('.direction');
    if (directionEl) {
        directionEl.innerText = Translator.trans('next_departures.direction') + directionName;
    }
}

function createDepartureTime(datetime, diffHour, isFreshData, departureObjectDatetime)
{
    const departureTime = document.createElement('div');
    departureTime.classList.add('departure_time');

    if (isFreshData === true) {
        departureTime.innerHTML = '<img class="realtime-picto" src="/bundles/canaltppnpcore/images/realtime.gif" alt="realtime" />';
    }
    departureTime.innerHTML += diffHour.diff > 59 ? displayHour(datetime, departureObjectDatetime, diffHour.isTomorrow) : Math.ceil(diffHour.diff) + ' min'; // hh:mm
    return departureTime;
}

function updateDepartureTime(element, datetime, diffHour, isFreshData, departureObjectDatetime)
{
    let departureTimeEl = element?.querySelector('.departure_time');
    if (!departureTimeEl) {
        return;
    }

    departureTimeEl.innerHTML = '';
    if (isFreshData === 'realtime' === true) {
        departureTimeEl.innerHTML = '<img class="realtime-picto" src="/bundles/canaltppnpcore/images/realtime.gif" alt="realtime" />';
    }
    departureTimeEl.innerHTML += diffHour.diff > 59 ? displayHour(datetime, departureObjectDatetime, diffHour.isTomorrow) : Math.ceil(diffHour.diff) + ' min';
}

function getPtSection(sections) {
    let PtSection = [];
    sections.forEach(function (section) {
        if (card_config.journey_section_type.includes(section.type) && PtSection.length === 0) {
            PtSection = section;
        }
    });

    return PtSection;
}

function calcdiffHour(datetime, isFirstDatetime) {
    const date = transformDateTime(datetime);
    const diffDay = calculateDateDifference(date);
    let isTomorrow = false;

    // calculate diff only for the first datetime
    if (isFirstDatetime) {
        let formattedDate = Translator.trans('timetables.today');
        if (diffDay > 0) {
            formattedDate = date.toLocaleDateString(window.Kisio.locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });

            if (diffDay === 1) {
                formattedDate = Translator.trans('timetables.tomorrow');
                isTomorrow = true;
            }
        }

        card_config.departure_day_el.innerText = formattedDate;
    }

    let diffDatetime = date - new Date();
    diffDatetime = Math.ceil(diffDatetime/1000);

    const diffSec = diffDatetime % 60;

    let diffMin = Math.ceil((diffDatetime - diffSec)/60);
    diffMin = diffMin % 60;

    return {
        diff: Math.ceil((diffDatetime - diffMin)/60),
        isTomorrow
    };
}

function displayHour (datetime, departureObjectDatetime, isTomorrow=false) {
    const date = transformDateTime(datetime);
    let res = "";
    let parsedDate = parseDate(departureObjectDatetime);

    if (parsedDate == '') {
        parsedDate = new Date();
        if (isTomorrow) {
            parsedDate.setDate(parsedDate.getDate() + 1);
        }
    }

    let dayDiff = getDaysDifference(parsedDate, date);

    if (dayDiff >= 1) {
        res += `<span>(+${dayDiff}${Translator.trans('schedule.days.short')})</span> `
    }

    res += datetime.substr(9, 2) + ':' + datetime.substr(11, 2);
    return res;
}

function parseDate(departureObjectDatetime) {
    if (typeof departureObjectDatetime == 'undefined') {
        return '';
    }

    const monthTranslations = {
        [Translator.trans('schedule.months.1')]: 'January',
        [Translator.trans('schedule.months.2')]: 'February',
        [Translator.trans('schedule.months.3')]: 'March',
        [Translator.trans('schedule.months.4')]: 'April',
        [Translator.trans('schedule.months.5')]: 'May',
        [Translator.trans('schedule.months.6')]: 'June',
        [Translator.trans('schedule.months.7')]: 'July',
        [Translator.trans('schedule.months.8')]: 'August',
        [Translator.trans('schedule.months.9')]: 'September',
        [Translator.trans('schedule.months.10')]: 'October',
        [Translator.trans('schedule.months.11')]: 'November',
        [Translator.trans('schedule.months.12')]: 'December',
    };

    if (departureObjectDatetime == Translator.trans('timetables.tomorrow')) {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow; 
    }

    try {
        const [day, month, year] = departureObjectDatetime.split(' ');
        const englishMonth = monthTranslations[month.toLowerCase()];
        const transformedDate = new Date(`${englishMonth} ${day}, ${year}`);

        return transformedDate;
    } catch {};

    return "";
}

function getDaysDifference(date1, date2) {
    // Convert both dates to UTC to avoid issues with daylight saving time
    const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  
    // Calculate the difference in milliseconds
    const timeDifference = utcDate2 - utcDate1;
  
    // Convert the difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysDifference;
}

async function refreshDeparturesFromSchedule(query, departures, departureObjectDatetime) {
    let dates = await callApiTerminusSchedule(query);
    let i=0;
    for (let datetime in dates)  {
        if(i > departures.length -1) {
            break;
        }

        let direction = dates[datetime]['direction'];
        let diffHour = calcdiffHour(datetime, i === 0);

        updateDirection(departures[i], direction);

        updateDepartureTime(departures[i], datetime, diffHour, dates[datetime]['data_freshness'], departureObjectDatetime);

        i++;
    }
}

async function refreshDeparturesFromJourney(departureObject) {
    const query = {
        departureId: departureObject.departure.stop_area,
        arrivalId: departureObject.arrival.stop_area,
        lineId: departureObject.line_id,
        setCount: 10
    };

    if (departureObject.datetime) {
        query.dateTime = departureObject.datetime;
    }

    callApiNextDepartures(query).then(function (data) {
        let departures = document.querySelectorAll('.departures .departures__item');
        data.journeys?.every(function (journey, key) {
            if (key > 2) {
                return false;
            }

            let PtSection = getPtSection(journey.sections);

            let datetime = PtSection.departure_date_time;
            let diffHour = calcdiffHour(datetime, key === 0);

            updateDirection(departures[key], PtSection?.display_informations.direction);
            updateDepartureTime(departures[key], datetime, diffHour, PtSection.data_freshness === 'realtime', departureObject.datetime);

            return true;
        });
    })
}

function calculateDateDifference(datetime) {
    const date = new Date(datetime);
    const currentDate = new Date();

    date.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const diffMilliseconds = date - currentDate;
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

    return Math.floor(diffDays);
}
