export function callApiPtObjects(params) {
    return callApi('r_public_transport_objects_filtered', params);
}

export function callApiNextDepartures(params) {
    return callApi('schedule_next_departures', params);
}

export function callApiTerminusSchedule(params) {
    return callApi('schedule_terminus_schedules', params);
}


export function callApiTimetable(params) {
    return callApi('schedule_timetable', params);
}

async function callApi(routeName, params) {
    let result = [];
    await JKisio.ajax({
        url: Routing.generate(routeName,
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                ...params
            }),
        success: function (data) {
            result = data;
        },
        error: function (error) {
            console.log(error);
        }
    });

    return result;
}
