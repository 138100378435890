import {setElementAttributes} from "../../utils";

export function createSelect(items) {
    const selectSummary = document.createElement('summary');
    selectSummary.classList.add('radios');

    if (!items || items.length <= 1) {
        selectSummary.setAttribute('disabled', 'disabled');
        selectSummary.style.display = 'none';
    }

    const selectUl = document.createElement('ul');
    selectUl.classList.name = 'list';

    const select = document.createElement('details');
    select.appendChild(selectSummary);
    select.appendChild(selectUl);

    items?.forEach(function(item, index) {
        createInputsList(selectSummary, item.route.direction.name,'item' + index, index === 0 ? 1 : 0);
        createLabelsList(selectUl, item.route.direction.name, 'item' + index);
    });

    return select;
}

export function manageClickOnSelect(selectEl, eventName) {
    selectEl.addEventListener('click', function (event) {
        clickEventListener(event,  true, eventName, selectEl);
    });
    selectEl.querySelectorAll('input').forEach(function (input) {
        input.addEventListener('click', function (event) {
            clickEventListener(event,  false, eventName, selectEl, input);
        });
    });
}

export function clickEventListener(event, isDetailsEvent, eventName, selectEl, input = null) {
    if (false === isDetailsEvent && selectEl.hasAttribute("open")) {
        document.body.dispatchEvent(new CustomEvent(eventName, {detail: {selected_input: input}}));
        selectEl.removeAttribute("open");
        return;
    }

    const checkedInput = document.querySelector('input.direction-input:checked');

    if (checkedInput) {
        const checkedLabel = document.querySelector(' label[for="' + checkedInput.id + '"]');
        if (checkedLabel) {
            checkedLabel.style.display = 'none';
        }
    }

    const uncheckedLabels = document.querySelectorAll('label.direction-label[for]:not([for="' + checkedInput.id + '"])');
    uncheckedLabels.forEach(function (uncheckedLabel) {
        uncheckedLabel.style.display = 'block';
    });

    if(!isDetailsEvent) {
        selectEl.setAttribute("open", "");
        event.preventDefault();
    }
}

function createInputsList(parent, value, id, checked = false) {
    const input = document.createElement('input');
    const attributes = {
        type: 'radio',
        name: 'item',
        id: id,
        title: value,
        class: 'direction-input'
    };

    if (checked) {
        attributes.checked = "checked";
    }

    setElementAttributes(input, attributes);

    parent.appendChild(input);

    return input;
}

function createLabelsList(parent, value, id) {
    // if(checked) return;
    const selectLi = document.createElement('li');
    const selectLabel = document.createElement('label');
    selectLabel.setAttribute('for', id);
    selectLabel.setAttribute('class', 'direction-label');
    selectLabel.innerHTML = value;

    selectLi.appendChild(selectLabel);
    parent.appendChild(selectLi);
}
