import {callApiNextDepartures} from "../api";
import {createBackLink, createLoader} from "../../utils";
import {createDeparturesCard} from "./card";
import {displayLineOnMap, displayStopPointsOnMap} from "../map";
import {createTimeTableSection} from "../time-table";
import {displayVehiclePositionByRouteId} from "../vehicle-positions";
import {getDisruptions, manageDisruptionsEventListeners} from "../disruptions";
import { clearStopToolTip, displayDepartureArrivalOnMap } from "../map";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

const departures_conf = {
    line_form: '.schedule-line-form',
    departures: 'schedule-line-departures',
    layer_section_name: 'schedule_line_departures',
    prev_layer_section_name: 'schedule_line',
    journey_section_type: ['public_transport', 'on_demand_transport'],
    tootip_departure: 'tooltip__item_departure',
    tooltip_arrival: 'tooltip__item_arrival'
}

export function displayScheduleLineDepartures(departureObject) {
    departureObject.stop_point = null;
    const departureEl = document.querySelector(`.${departures_conf.departures}`);
    if (departureEl) {
        departureEl.remove();
    }

    const lineForm = document.querySelector(departures_conf.line_form);
    lineForm.classList.add('gl-hide');

    const departureContainerEl = document.createElement('div');
    departureContainerEl.classList.add(departures_conf.departures);
    departureContainerEl.dataset.layerSection = departures_conf.layer_section_name;
    departureContainerEl.innerHTML = createLoader();

    lineForm.after(departureContainerEl);

    const query = {
        departureId: departureObject.departure.stop_area,
        arrivalId: departureObject.arrival.stop_area,
        lineId: departureObject.line_id,
        setCount: 10,
        opening_time: departureObject.opening_time,
    };
    if (departureObject.datetime) { 
        query.dateTime = departureObject.datetime;
    }

    callApiNextDepartures(query).then(function (data) {
        // clean loader
        departureContainerEl.innerHTML = '';

        departureContainerEl.appendChild(createBackLink(lineForm, departureContainerEl, departures_conf));
        departureContainerEl.appendChild(getDisruptions(departureObject));
        
        createDeparturesCard(data.journeys, departureObject).then((element) => {
            departureContainerEl.appendChild(element);
            displayVehiclePositionByRouteId(departureObject.line_id, departureObject.departure.stop_area);

            let PtSection;
            departureObject.directions = [];

            data.journeys?.forEach(function (journey, index) {
                journey.sections.forEach(function (section) {
                    if (departures_conf.journey_section_type.includes(section.type)) {
                        if (!departureObject.stop_point) {
                            departureObject.stop_point = section.from.id;
                            PtSection = section;
                        }
                        if (departureObject.stop_point === section.from.id) {
                            let directionId;
                            section.display_informations.links.map(function (link) {
                                if (link.type === 'stop_area') {
                                    directionId = link.id
                                }
                            });

                            if (!departureObject.directions.find(
                              item => item.id === directionId && item.name === section.display_informations.direction)
                            ) {
                                departureObject.directions.push({
                                    id: directionId,
                                    name: section.display_informations.direction
                                });
                            }
                        }
                    }
                });
            });
            departureContainerEl.appendChild(createTimeTableSection(departureObject));
            const lineObject = {
                ...PtSection?.display_informations,
                geojson: PtSection?.geojson,
                physical_modes: []
            };
            const stopPointsSection = [];
            PtSection?.stop_date_times.forEach(function (stop_date_time) {
                stopPointsSection.push(stop_date_time.stop_point);
            });

            document.body.dispatchEvent(new CustomEvent("opacity_layers_section_event", {detail: {
                    section: "schedule",
                    layer: departures_conf.prev_layer_section_name,
                    opacity: .2
                }}));

            displayLineOnMap(lineObject, departures_conf.layer_section_name);
            displayStopPointsOnMap(stopPointsSection, departureObject.line_id, departureObject, departures_conf.layer_section_name);
            clearStopToolTip(departures_conf.tootip_departure);
            clearStopToolTip(departures_conf.tooltip_arrival);
            displayDepartureArrivalOnMap(stopPointsSection, departureObject.color, departures_conf.layer_section_name);
            manageDisruptionsEventListeners();
            // just in case it's load with direct access
            simplebarManagement();
        }).catch(() => {});
    });
}

function simplebarManagement() {
    let content = document.querySelector('#schedule_content');

    if (content.SimpleBar) {
        content.SimpleBar.unMount();

    }
    new SimpleBar(content);
}
